<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start >
            <div class="p-d-flex p-ai-center p-flex-wrap">
              <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Warehouse items') }}</div>
              <AddNewButton :label="$t('Add')" @click="addNewItem" class="p-mr-2"/>
              <AddNewButton :label="$t('Add category')" @click="addNewCategory" class="p-mr-2" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant"/>
              <!--            <Button @click="actualizeReservedQty" class="p-button-secondary p-button-raised" icon="pi pi-refresh" label="Actualize"></Button>-->
              <Button class="p-button-secondary p-button-raised p-mr-2"
                      @click="changeWarehouseItemsCategoryOnClick"
                      icon="pi pi-file-edit"
                      v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant"
                      :disabled="!selectedItems?.length || dataTableIsLoading"
                      :label="`Change category ${selectedItems?.length ? `(${selectedItems?.length} items)`  : ''}`"></Button>
              <Button v-if="$store.state.user.role === constants.userRoles.superAdmin" @click="actualizeSorting" class="p-button-secondary p-button-raised p-mr-4" icon="pi pi-refresh" label="Actualize sorting"></Button>
              <div class="p-d-flex p-ai-center" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                <!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
                <Checkbox @change="toggleShowNotActive" :disabled="dataTableIsLoading" id="form-agree-inputswitch" :binary="true" v-model="showNotActive"/>
                <div>
                  <label for="form-agree-inputswitch" >
                    <div class="pointer p-p-2">Show not active</div>
                  </label>
                </div>
              </div>
            </div>
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" :clearLastSearchingValue="clearLastSearchingValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>


        <!--  Desktop DataTable -->
        <div class="p-d-flex">
          <div style="width: 15%; min-width: 300px;" class="p-mr-4">
            <Tree @node-select="changeCategory" :value="categoriesList" :expandedKeys="expandedKeys" selectionMode="single" v-model:selectionKeys="selectedCategory"></Tree>
          </div>

          <div style="width: 85%">
            <Spinner v-if="isLoading"></Spinner>
<!--            <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
            <DesktopDataTable v-else
                              :dataTableIsLoading="dataTableIsLoading"
                              :showHeader="false"
                              :tableName="$t('Warehouse items')"
                              :itemsArrayIsEmpty="itemsArrayIsEmpty"
                              :sort="sort"
                              style="width: 100%;"
                              :showSearchInput="false"
                              :actionButtonsCount="actionButtonsCount"
                              @sort-items="sortItems"
                              :headers="[
              {name: 'code', title: 'Code', sortable: true},
              {name: 'name', title: 'Name', sortable: true},
              {name: 'unit', title: 'Unit', sortable: true},
              {name: 'qty', title: 'Qty', sortable: true},
              {name: 'reserved_qty', title: 'Reserved', sortable: true},
              {name: 'available', title: 'Available', sortable: false},
              {name: 'purchase_price', title: 'Purchase price', sortable: true},
              {name: 'sell_price', title: 'Sell price', sortable: true},
              {name: 'margin', title: 'Margin', isHidden: $store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant, sortable: false},
              {name: 'status', title: '', sortable: !!showNotActive, isHidden: !showNotActive},
              // {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
              <template v-slot:multiselectHeader>
                <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">
                  <Checkbox @click="selectAllCheckboxOnClick" :disabled="dataTableIsLoading" :value="0" v-model="selectAllCheckbox"/>
                </th>
              </template>
              <template v-slot:body>
                <tr v-for="item of items"
                    :key="item.id"
                    :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
                  <td v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                    <Checkbox :value="item.id" :disabled="dataTableIsLoading" v-model="selectedItems"/>
                  </td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ units.find(unit => unit.num === item.unit)?.label[$i18n.locale] }}</td>
                  <td>
                    <span v-if="item.is_decimal">{{ formatDecimal(item.qty) }}</span>
                    <span v-else>{{ formatDecimal(item.qty, true, 0) }}</span>
                  </td>
                  <td>{{ formatDecimal(item.reserved_qty) }}</td>
                  <td>{{ formatDecimal(item.qty - item.reserved_qty) }}</td>
                  <td>
                    <span>{{ formatCurrency(item.purchase_price, 2, 4) }}</span>
                  </td>
                  <td>
                    <span>{{ formatCurrency(item.sell_price, 2, 4) }}</span>
                  </td>
                  <td v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">{{ calculateItemMargin(item) }}</td>
                  <td v-if="showNotActive">
<!--                    <div class="p-d-flex p-ai-center p-jc-center">-->
                      <WarehouseItemStatusButton :item="item" @update-items="updateItems"/>
<!--                    </div>-->
                  </td>
                  <td v-if="actionButtonsCount">
                    <div class="p-d-flex">
                      <EditButton :disabled="disableEditButton || $store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant"
                                  @click="editItem(item)" />
<!--                      <DeleteButton @click="confirmItemDelete(item)"-->
<!--                                    v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant"/>-->
                    </div>
                  </td>
                </tr>
              </template>
            </DesktopDataTable>
          </div>
        </div>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <WarehouseItemModal :visible="itemModal"
                            :item="item"
                            :selectedCategoryId="selectedCategoryId"
                            @update-items="updateItems"
                            @close="closeItemModal">
        </WarehouseItemModal>

        <WarehouseCategoryModal :visible="categoryModal"
                              :item="category"
                              @update-items="updateWarehouseCategory"
                              @close="closeCategoryModal">
        </WarehouseCategoryModal>

        <ChangeCategoryModal
            :visible="changeCategoryModal"
            :categories="categories"
            :selectedItems="selectedItems"
            @change-warehouse-items-category="changeWarehouseItemsCategory"
            @close="closeChangeCategoryModal">
        </ChangeCategoryModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import WarehouseCategoryModal from "@/pages/warehouse/components/WarehouseCategoryModal";
import WarehouseItemModal from "@/pages/warehouse/components/WarehouseItemModal";
import constants from '@/constants'
import formatMixins from "@/mixins/formatMixins";
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import units from "@/translations/units";
import ChangeCategoryModal from "@/pages/warehouse/components/ChangeCategoryModal";
import WarehouseItemStatusButton from "@/pages/warehouse/components/WarehouseItemStatusButton";
import settings from "@/settings";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  components: { WarehouseItemModal, WarehouseCategoryModal, ChangeCategoryModal, WarehouseItemStatusButton },
	data() {
		return {
      showNotActive: false,
      expandedKeys: {},
      keysAreExpanded: false,
      categoriesList: [
        {
          key: '0', label: this.$t('All categories'), icon: 'ti-folder', children: [],
          // key: '1', label: this.$t('Undefined'), icon: 'ti-folder', children: []
        }
      ],
      categories: [],
      fieldsExpand: '',
      apiLink: 'warehouse/get-active-items',
      routePath: '/warehouse-items',
      sort: {
        column: 'code',
        direction: '',
      },
      totalItemsCount: null,
      categoryModal: false,
      changeCategoryModal: false,
			category: {},
      units,
		}
	},
  watch: {
    '$i18n.locale'() {
      if (this.totalItemsCount) {
        this.categoriesList[0].label = `${this.$t('All categories')} (${this.totalItemsCount})`
      } else {
        this.categoriesList[0].label = this.$t('All categories')
      }

      const undefinedCategory = this.categoriesList[0].children.find(child => child.id === 0)
      if (undefinedCategory) {
        undefinedCategory.label = `${this.$t('Undefined')} (${undefinedCategory.count})`
      }
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.categoryModal) {
          this.closeCategoryModal()
        }
        if (this.changeCategoryModal) {
          this.closeChangeCategoryModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.getCategories().then(() => this.getItems())
	},
  methods: {
    updateWarehouseCategory() {
      this.getCategories().then(() => this.getItems())
    },
    toggleShowNotActive() {
      // this.getItems()
      this.isLoading = true
      this.getCategories().then(() => this.getItems())
      // this.updatedItemId = null
    },
    async actualizeSorting() {
      try {
        const { status } = await httpClient('warehouse/set-items-sorting')
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife})
        }
      } catch (err) {
        this.showError(err)
      }
    },
    // getItems() {
    //   this.getUserEmailAccounts().then((res) => {
    //     if (res?.success) {
    //       // if (closeMessage) {
    //       //   this.closeMessage()
    //       // }
    //       this.generateEmailsTree()
    //       this.selectFirstNode()
    //       if (getMessages) {
    //         this.getMessages(showSpinner, showToast)
    //       }
    //     }
    //   })
    // },
    changeWarehouseItemsCategory() {
      this.updatedItemsIds = [ ...this.selectedItems ]
      this.selectedItems = []
      this.getCategories().then(() => this.getItems())
      this.closeChangeCategoryModal()
      // this.unselectAllItems()
    },
    changeWarehouseItemsCategoryOnClick() {
      this.changeCategoryModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeChangeCategoryModal() {
      this.changeCategoryModal = false
      this.$store.commit('toggleFirstLayer', false)
      this.submitted = false
    },
    async actualizeReservedQty() {
      try {
        const { status } = await httpClient('warehouse/actualize-reserved-qty')
        if (status === 200) {
          this.getItems(true)
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: 1750})
        }
      } catch(err) {
        this.showError(err)
      }
    },
    calculateItemMargin(item) {
      if (!item) return false
      const margin = item.purchase_price ? ((((item.sell_price - (((item.discount || 0) / 100) * item.sell_price)) * 100) / (item.purchase_price || 0)) - 100).toFixed(2) : ''
      if (margin && isFinite(margin)) {
        return margin + '%'
      }
    },
    changeCategory(node) {
      // console.log(node)
      // const selectedCategoryKey = Object.keys(this.selectedCategory)[0]
      // console.log(selectedCategoryKey)

      if (this.categoriesList && this.categoriesList.length && this.categoriesList[0].children) {
        const selectedCategory = (this.categoriesList[0].children).find(child => child.key === node.key)
        this.selectedCategoryId = selectedCategory ? selectedCategory.id : null
      }

      // this.clearSearchingData()

      const route = {
        route: this.$route.path,
        query: {
          ...this.$route.query,
          category: this.selectedCategoryId,
        }
      }
      // if (this.searchData?.length) {
      //   route.query.search = this.searchData
      // }

      if (!this.selectedCategoryId && this.selectedCategoryId !== 0) {
        delete route.query.category
      }
      this.$router.push(route)
    },
    expandAll() {
      for (let category of this.categoriesList) {
        this.expandCategory(category);
      }
      this.keysAreExpanded = true
      this.expandedKeys = {...this.expandedKeys};
    },
    collapseAll() {
      this.expandedKeys = {};
      this.keysAreExpanded = false
    },
    expandCategory(category) {
      this.expandedKeys[category.key] = true;
      if (category.children && category.children.length) {
        for (let child of category.children) {
          this.expandCategory(child);
        }
      }
    },
    async getCategories() {
      // console.log('get categories')
      const expandExtraFields = this.showNotActive ? 'allItemsCount' : 'activeItemsCount'
      try {
        const { status, data } = await httpClient.post('warehouse/get-categories?sort=sorting&expand=' + expandExtraFields, {
          getUndefinedCount: true,
          showNotActive: this.showNotActive
        })
        if (status === 200 && data) {
          const categories = data[0] ?? []
          const undefined_items_count = data[1] ?? 0
          const children = []
          let i = 0
          if (+undefined_items_count) {
            children.push({
              key: '0-' + i++,
              id: 0,
              label: `${ this.$t('Undefined') } (${undefined_items_count || 0})`,
              icon: 'ti-folder',
              count: +undefined_items_count || 0
            })
          }
          if (categories.length) {
            categories.forEach(category => {
              const itemsCount = category.activeItemsCount || category.allItemsCount || 0
              children.push({
                key: '0-' + i++,
                id: category.id,
                label: `${category.name} (${itemsCount})`,
                icon: 'ti-folder',
                count: +itemsCount
              })
            })
            this.categories = categories
          }

          if (children && children.length) {
            this.totalItemsCount = children.reduce((sum, child) => sum + child.count, 0)
            this.categoriesList[0].label = `${this.$t('All categories')} (${this.totalItemsCount})`
            this.categoriesList[0].children = children
            this.expandAll()
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.checkRouteQuery()
      }
    },
    // updateItems(service, clearItem = false) {
    //   if (service) {
    //     this.updatedItemId = service.id
    //   }
    //   if (clearItem) {
    //     this.item = {}
    //     this.submitted = false
    //   }
    //   // this.getItems()
    //   this.getCategories()
    //
    //   // if (service.isNew) {
    //   //   this.getCategories(true)
    //   // } else {
    //   //   this.getCategories(false)
    //   // }
    // },
    updateItems(itemId, getItems = true) {
      if (itemId) {
        this.updatedItemId = itemId
      }

      if (getItems) {
        this.getCategories().then(() => this.getItems())
      }

      this.$emit('update-items') // Important: this is for update items in parent TAB component
    },
    // updateWarehouseItem(item) {
    //   if (item.isNew || item.categoryWasChanged) {
    //     console.log(1)
    //     this.getCategories()
    //   } else {
    //     console.log(2)
    //     this.getItems()
    //   }
    // },
    // checkRouteQuery() {
    //   if (this.$route.query.page) {
    //     this.pagination.currentPage = +this.$route.query.page
    //   } else {
    //     this.pagination.currentPage = 1
    //   }
    //   if (this.$route.query.perPage) {
    //     this.pagination.perPage = +this.$route.query.perPage
    //   } else {
    //     this.pagination.perPage = 25
    //   }
    //   if (this.$route.query.sort) {
    //     const firstSign = this.$route.query.sort[0]
    //     if (firstSign === '-') {
    //       this.sort.direction = '-'
    //       let sortColumn = this.$route.query.sort.split('')
    //       this.sort.column = sortColumn.splice(1).join('')
    //     } else {
    //       this.sort.direction = ''
    //       this.sort.column = this.$route.query.sort
    //     }
    //   }
    //
    //   // if (this.$route.query.category && this.$route.query.category !== '0') {
    //   if (this.$route.query.category) {
    //     this.selectedCategoryId = +this.$route.query.category
    //     const key = this.categoriesList[0].children.find(child => child.id === +this.$route.query.category)?.key
    //     if (key)  {
    //       const obj = {}
    //       obj[key] = true
    //       this.selectedCategory = {...obj}
    //     } else {
    //       this.selectedCategory = { 0: true }
    //       this.selectedCategoryId = null
    //     }
    //   } else {
    //     this.selectedCategory = { 0: true }
    //     this.selectedCategoryId = null
    //   }
    //
    //   if (this.$route.query.search) {
    //     this.searchData = this.$route.query.search
    //   } else {
    //     this.searchData = ''
    //   }
    // },
    async getItems(showSpinner = false) {
      // console.log('getItems')
      this.dataTableIsLoading = true
      this.disableEditButton = true
      if (showSpinner) {
        this.isLoading = true
      }
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?categoryId=${this.selectedCategoryId}&page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            categoryId: this.selectedCategoryId,
            showNotActive: this.showNotActive,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
        this.checkAllItemsAreSelected()
      }
    },
    closeCategoryModal() {
      this.categoryModal = false
      this.$store.commit('toggleFirstLayer', false)
      this.submitted = false
    },
    addNewCategory() {
      this.category = {}
      this.submitted = false
      this.categoryModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    async deleteItem() {
      console.log('delete warehouse item')
    },
	},
  computed: {
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin || this.$store.state.user.role === constants.userRoles.accountant) {
        return 1
      } else {
        return 0
      }
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">
.radio-button-label {
  padding: 6px;
  margin-left: 0;
  cursor: pointer;
}
</style>